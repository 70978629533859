<template>
  <div class="Financial__Fortnight">
    <TheSubHeader
      :actions="['back']"
      type="filter"
      :title="fortnightName"
      page-title-tag="Financial Fortnight"
      organizer-gap="1rem"
      organizer-columns="500px"
      icon="money"
    >
      <template #afterButtons>
        <BaseButton
          color="primary-base"
          label="Baixar"
          class="operation-button"
          :track="trackSubheaderButtonString('Financial Fortnight', 'Download')"
          @click="downloadReport('fortnight')"
        />
        <BaseButton
          color="primary-base"
          label="Baixar CNAB"
          class="operation-button"
          :disabled="!downloadCnab"
          :track="trackSubheaderButtonString('Financial Fortnight', 'Download CNAB')"
          @click="downloadReport('cnab')"
        />
        <BaseTreeSelect
          v-model="filters.status"
          placeholder="Selecione"
          :enable-paste="false"
          :select-options="statusList"
          :searchable="false"
          :clearable="false"
          :rounded="true"
          :button-type="true"
          :disabled="changeStatus"
          @select="setNewStatus"
        />
      </template>

      <template #filter>
        <!-- Driver -->
        <BaseTreeSelect
          v-model="filters.drivers"
          name="Motorista"
          label="Motorista"
          placeholder="Pesquisar"
          multiple
          suggestions="searchDrivers"
          @input="updateTags(), handlerFortnightRequest()"
        />
      </template>

      <!-- filters tag -->
      <template v-if="allTags.length > 0" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    
    <section class="Financial__Content">
      <div class="Financial__tab">
        <DataTable
          :header="fortnight.data.headers"
          :table="fortnightTableWithId"
        />

        <Pagination @page="fetchFortnight" @paginate="fetchFortnight">
          <div v-if="totalCostValue && totalCostValue.baseValue && totalCostValue.total" class="Financial__cost">
            <strong>Valor base: </strong>{{ totalCostValue.baseValue }}
            <br>
            <strong>Valor total consolidado: </strong>{{ totalCostValue.total }}
          </div>
        </Pagination>
      </div>
    </section>

    <DownloadSpreadsheetModal ref="DownloadSpreadsheet" :page="downloadType" />

    <Dialog
      v-if="modal"
      class="Financial__dialog"
      :warning="changeStatusMessage"
      :label-cancel="successMessage ? false : 'Cancelar'"
      :label-confirm="successMessage ? 'Confirmar' : 'Enviar'"
      :listener-keyup="false"
      @cancel="closeModal"
      @confirm="handleModal"
    />
  </div>
</template>

<script>
import { TheSubHeader } from '@/components/organisms'
import { mapActions, mapState, mapGetters} from 'vuex'
import { BaseTreeSelect, BaseButton } from '@/components/atoms'
import { FiltersTag, DataTable, Pagination, Dialog } from '@/components/molecules'
import { financial } from '@/api'
import Mask from '@/plugins/mask/Mask.js'
import FinancialMixin from '@/mixins/financial'
import DownloadSpreadsheetModal from '@/views/financial/DownloadSpreadsheetModal'

export default {
  name: 'Fortnight',
  components: {
    TheSubHeader,
    BaseTreeSelect,
    FiltersTag,
    BaseButton,
    DataTable, 
    Pagination,
    DownloadSpreadsheetModal,
    Dialog
  },

  mixins:[FinancialMixin],

  data() {
    return {
      allTags: [],
      modal: false,
      currentStatus: null,
      filters: {
        drivers: [],
        status: null
      },
      successMessage: '',
      downloadType: null,

      reportsTable: {
        headers: {
          id: { id: 'id', isVisible: false, label: 'ID', param: 'id' },
          name: { id: 'name', isVisible: true, label: 'Planilhas', param: 'name' },
          type: { id: 'type', isVisible: true, label: 'Tipo de operação', param: 'type'},
          actions: { actions: true, id: 'actions', isVisible: true, label: '', param: 'actions' },
        },
        content: [
          { id: 1, name: 'Pré Consolidado Financeiro', type: 'online/offline', actions: [ { action_id: 'download', has_permission: true  } ] },
          { id: 2, name: 'Pré Consolidado por diárias', type: 'online', actions: [ { action_id: 'download', has_permission: true } ] },
          { id: 0, name: 'Consolidado financeiro', type: 'online/offline', actions: [ { action_id: 'download', has_permission: true  } ] },
        ],
      },

      cnabTable: {
        headers: {
          id: { id: 'id', isVisible: false, label: 'ID', param: 'id' },
          type: { id: 'type', isVisible: true, label: 'Método de pagamento', param: 'type' },
          actions: { actions: true, id: 'actions', isVisible: true, label: '', param: 'actions' }
        },
        content: [
          { id: 0, type: 'TED', actions: [ { action_id: 'download', has_permission: true  } ] },
          { id: 1, type: 'PIX', actions: [ { action_id: 'download', has_permission: true  } ] }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      fortnight: state => state.financial.fortnight,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate
    }),

    ...mapGetters({
      fortnightTableWithId: 'financial/fortnightTableWithId'
    }),

    changeStatusMessage() {
      return this.successMessage ? this.successMessage : `Esta ação irá alterar o status para <strong>${this.currentStatus}</strong>. Deseja continuar?`
    },

    totalCostValue() {
      return {
        baseValue: Mask.money(this.fortnight?.data?.append?.append_total_base_value),
        total: Mask.money(this.fortnight?.data?.append?.append_total_cost_value)
      }
    },

    fortnightName() {
      return this.fortnight.data.append.append_page_title || 'Consolidado'
    },

    downloadCnab() {
      // status 'payment approved' and 'concluded'
      return !!(this.filters.status?.code === 9 || this.filters.status?.code === 12)
    },

    changeStatus() {
      return !!(this.filters.status?.code === 9)
    }
  },

  mounted() {
    this.initStatusList()
    this.clearPagination(20)
    this.fetchFortnight()
  },

  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      clearPagination: 'pagination/clearPagination',
      setError: 'error/setError',
      fetchFortnightAction: 'financial/fetchFortnight',
      setPagination: 'pagination/setPagination'
    }),

    async fetchFortnight() {
      const params = {
        filters: {
          driver_id: this.filters?.drivers,
        },
        id: this.$route.params.id || 0,
        page: this.page,
        paginate: this.paginate
      }
      await this.fetchFortnightAction(params, (res => res, (e) => e)).then(() => this.setStatus())
      this.setPagination(this.fortnight)
    },

    setStatus() {
      this.filters.status = this.fortnight.data.append.append_page_status || null
    },

    closeModal() {
      this.modal = false
      this.setStatus()
    },

    setNewStatus(data) {
      if (data) this.modal = true
      this.currentStatus = data?.name
    },

    handlerFortnightRequest() {
      this.clearPagination(20).then(() => this.fetchFortnight())
    },

    async changeConsolidateStatus(){
      this.setLoading(true)
      const body = {
        id: this.$route?.params?.id,
        status_code: this.filters.status?.code
      }
      
      await financial.changeConsolidateStatus(body, (res) => {
        this.successMessage = res?.message
      }, (e) => {
        this.modal = false
        this.setError(e?.message)
      }, () => this.setLoading(false))
    },

    handleModal() {
      if(!this.successMessage) {
        return this.changeConsolidateStatus()
      } else {
        this.modal = false
        this.successMessage = ''
      }
    },

    updateTags(){
      this.allTags = [...this.filters.drivers]
    },
    
    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.drivers = this.filters.drivers.filter((tag) => tag.name !== name)
      this.handlerFortnightRequest()
    },

    clearAllTags(){
      this.allTags = []
      this.filters.drivers = []
      this.handlerFortnightRequest()
    },

    downloadReport(type) {
      this.downloadType = type
      this.$refs.DownloadSpreadsheet?.open(type === 'fortnight' ? this.reportsTable : this.cnabTable, this.$route.params?.id)
    }
  },
}
</script>

<style lang="scss" scoped>
.Financial {
  &__Content {
    display: flex;
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    max-width: 1180px;
    flex-direction: column;
    padding: 0 0 3rem 0;
    @media (max-width: $viewport-lg) {
      padding: 0 1rem 3rem 1rem;
    }
  }

  &__tab {
    width: 100%;
  }

  &__cost {
    text-align: center;
    font-size: $font-size-mini;
  }

  &__dialog {
    .Dialog__Texts {
      width: 100%;
      margin-top: 0;

      .Dialog__Warning {
        padding: 10px 20px;
      }
    }
  }
}
</style>